import omit from 'lodash/omit';
import { useMemo, useState } from 'react';
import { Prompts2 } from 'shared/foreground/ghostreader';

export function useFilteredPrompts(prompts: Prompts2) {
  const [searchQuery, setSearchQuery] = useState('');
  const filteredPrompts = useMemo(() => {
    const internalPrompts = [];
    for (const scope of prompts.data) {
      const scopePrompts = [];
      for (const prompt of scope.prompts) {
        if (prompt.isCustomizable !== undefined && !prompt.isCustomizable) {
          continue;
        }

        if (prompt.title.toLowerCase().includes(searchQuery)) {
          scopePrompts.push(prompt);
        }
      }
      if (scopePrompts.length > 0) {
        internalPrompts.push({
          ...omit(scope, 'prompts'),
          prompts: scopePrompts,
        });
      }
    }
    return internalPrompts;
  }, [searchQuery, prompts]);

  const search = (query: string) => setSearchQuery(query.toLocaleLowerCase());

  return { search, filteredPrompts };
}
