import React from 'react';
import { PromptScopeType } from 'shared/foreground/ghostreader';

import Button from '../../../Button';
import styles from '../GhostreaderPromptsPage.module.css';

type Props = {
  promptScopeType: PromptScopeType;
  onClick: (promptScopeType: PromptScopeType) => void;
};

export function NewPrompt({ promptScopeType, onClick }: Props) {
  return (
    <li className={`${styles.ghostreaderPromptData} ${styles.noBorder}`}>
      <div className={styles.actions}>
        <Button onClick={() => onClick(promptScopeType)} variant="primary">
          Add prompt
        </Button>
      </div>
    </li>
  );
}
